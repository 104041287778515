<template>
  <b-tabs>
    <b-tab
      title="Daten"
      active
    >
      <b-card
        title="Variablen"
        sub-title="kurze Info dazu"
      >
        <label
          for="grp1"
          class="text-warning"
        >
          Vertragspartner
        </label>
        <b-form-tags
          id="grp1"
          v-model="test1"
          tag-pills
          tag-variant="warning"
        />
        <label
          for="grp2"
          class="text-warning"
        >
          Name des Tools
        </label>
        <b-form-tags
          id="grp2"
          v-model="test2"
          tag-pills
          tag-variant="warning"
        />
      </b-card>
      <b-card
        v-if="section"
        title="Absatz"
        sub-title="kurze Info dazu"
      >
        <label for="editor">Text</label>
        <quill-editor
          id="editor"
          v-model="section.textContent"
          :options="{theme: 'bubble', modules: {toolbar: '#toolbar'}}"
        >
          <div
            id="toolbar"
            slot="toolbar"
          >
            <button class="ql-bold">
              Bold
            </button>
            <button class="ql-italic">
              Italic
            </button>

            <button
              style="width:auto; color: white"
              @click="createVariable"
            >
              Variable
            </button>
          </div>
        </quill-editor>
        <b-button
          class="mr-1 mt-1"
          variant="outline-primary"
          size="sm"
          @click="save"
        >
          Save
        </b-button>
      </b-card>
    </b-tab>
    <b-tab title="Regeln">
      TEST
    </b-tab>
    <b-tab
      title="Extra"
      disabled
    />
  </b-tabs>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

import {
  BBadge,
  BButton,
  BCard,
  BCardText,
  BCol,
  BFormTextarea,
  BRow,
  BSpinner,
  BTabs,
  BTab,
  BListGroup,
  BListGroupItem,
  BSidebar, BCardBody, BCardTitle, BCardSubTitle, BFormTags,
} from 'bootstrap-vue'

export default {
  components: {
    BFormTags,
    BButton,
    BCard,
    // BCardText,
    BTabs,
    BTab,
    // BCardBody,
    // BCardTitle,
    // BCardSubTitle,
    quillEditor,
  },
  data() {
    return {
      visible: false,
      section: null,
      test1: ['Firma', 'Straße'],
      test2: ['Toolname'],
    }
  },
  mounted() {
  },
  methods: {
    show(section) {
      this.visible = true
      this.section = { ...section }
    },
    save() {
      console.log('SAFE')
      this.$emit('update:section', this.section)
    },
    createVariable(text) {
      this.test1.push('selected text')
    },
  },
}
</script>

<style>

</style>
