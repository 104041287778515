<template>
  <b-card>
    <b-overlay
      :show="loading"
      variant="dark"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-row>
        <b-col cols="8">
          <div
            class="document-text"
            style="overflow-y: scroll; height:calc(80vh - 40px);"
          >
            <app-collapse accordion>
              <app-collapse-item
                v-for="(section) of sections"
                :key="section.id"
                :title="section.title"
              >
                <div
                  v-for="subsection of section.subsections"
                  :key="subsection.id"
                >
                  <h5
                    v-if="subsection.title"
                    :class="'pt-1 pl-' + (subsection.level + 1)"
                    @click="editSection(section)"
                  >
                    {{ subsection.title }}
                  </h5>
                  <div
                    v-if="subsection.textContent"
                    :class="'pt-1 pl-' + (subsection.level + 1)"
                    @click="editSection(subsection)"
                    v-html="subsection.textContent"
                  />
                </div>
              </app-collapse-item>
            </app-collapse>
          </div>
        </b-col>
        <b-col
          cols="4"
          style="border-left: 1px dashed #555555"
        >
          <edit-section
            ref="editsection"
            @update:section="onUpdate($event)"
          />
        </b-col>
      </b-row>
    </b-overlay>
  </b-card>
</template>

<script>
import Vue from 'vue'

import {
  BButton,
  BOverlay,
  BCard,
  BCol,
  BFormTextarea,
  BRow,
  BBadge,
  BSpinner,
  BTabs,
  BTab,
  BCardText,
  BListGroup,
  BListGroupItem,
  BSidebar,
  BNavbar,
  BNavbarBrand,
  BNavItem,
  BNav,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import EditSection from '@/views/pages/EditSection.vue'

export default {
  components: {
    BRow,
    BOverlay,
    BCol,
    BCard,
    // BButton,
    // BSpinner,
    // BCardText,
    // BTabs,
    // BTab,
    // BListGroup,
    // BListGroupItem,
    // BSidebar,
    // BFormTextarea,
    EditSection,
    // BNavbar,
    // BNavbarBrand,
    // BNav,
    // BNavItem,
    AppCollapse,
    AppCollapseItem,
  },
  data() {
    return {
      loading: false,
      sections: [],
      editedSection: {},
    }
  },
  computed: {
    // titleSections() {
    //   return this.sections.filter(section => section.level === 0 && section.title)
    // },
    titleSections() {
      return this.sections.filter(section => section.title)
    },
  },
  mounted() {
    this.loading = true
    this.$http.get('/api/wc/legal-document/sections/7ddce9db-cc60-4687-bd16-4a0910b94463')
      .then(res => {
        let currentSection = {}
        let currentLevel = 0
        // eslint-disable-next-line guard-for-in
        res.data.sections.forEach(section => {
          if (section.level <= currentLevel && section.title) {
            currentLevel = section.level
            currentSection = section
            currentSection.subsections = []
            this.sections.push(currentSection)
          } else {
            currentSection.subsections.push(section)
          }
        })
        this.loading = false
      })
  },
  methods: {
    editSection(section) {
      this.editedSection = section
      this.$refs.editsection.show(section)
    },
    onUpdate(section) {
      console.log('UPDATE: ', section)
      Object.assign(this.editedSection, section)
    },
    save(section) {
      this.$http.post(`api/wc/legal-document/sections/${section.id}`, section)
        .then(
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Gespeichert',
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          }),
        )
    },
    remove(section) {
      this.$bvModal
        .msgBoxConfirm('Wirklich löschen?', {
          cancelVariant: 'outline-secondary',
        })
        .then(confirm => {
          if (confirm) {
            this.$http.delete(`api/wc/legal-document/sections/${section.id}`)
              .then(value => {
                Vue.delete(this.sections, this.sections.indexOf(section))
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Gelöscht',
                    icon: 'CoffeeIcon',
                    variant: 'warning',
                  },
                })
              })
          }
        })
    },
  },
}
</script>

<style lang="scss">
/* Hide scrollbar for Chrome, Safari and Opera */
.document-text::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.document-text {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
